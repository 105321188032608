

const formatFilters = (filters, userId) => {

    const formattedFilters = [`document.reviewer.id = ${userId}`]
    if (filters["reviewed"]) {
        formattedFilters.push("document.status<>SENT AND document.status<>ASSIGNED AND document.status<>IN_REVIEW")
    } else {
        formattedFilters.push("document.status=IN_REVIEW")
    }

    if (filters["deadlineYear"] != "all") {
        const startDate = `${filters["deadlineYear"]}-01-01`;
        const endDate = `${filters["deadlineYear"]}-12-31`;
        formattedFilters.push(`deadline>=${startDate} AND deadline<=${endDate}`)
    }

    const filtersString = formattedFilters.join(" AND ");
    return filtersString
}

export { formatFilters }