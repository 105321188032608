import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import css from "resources/css/App.module.css";
import Login from "components/auth/Login";
import LoginAuth from "components/auth/LoginAuth";
import Home from "components/Home";
import ReviewHome from "components/ReviewHome";
import NotFound from "components/utils/NotFound";
import Review from "components/partner/Main";
import ViewQaApprovalCC from "components/partner/ViewQaApprovalCc";
import Signers from "components/sign/Signers";
import NdRedirect from "components/sign/NdRedirect";
import QaApproval from "components/form/QaApproval";
import QaRequest from "components/form/QaRequest";
import QaApprovalV1 from "components/form/v1/QaApprovalV1";
import QaRequestV1 from "components/form/v1/QaRequestV1";
import Index from "components/sign/Index";
import IndexForm from "components/form/Index";
import NoVerify from "components/form/NoVerify";
import NowVerified from "components/form/NowVerified";
import Request from "components/requests/Request";
import Auditory from "components/approval/Auditory";
import Logout from "components/auth/Logout";
import HcDetails from "components/requests/HcDetails";


const App = () => (
  <div class={css.module} style={{ overflow: "auto" }}>
    <BrowserRouter basename="/" >
      <Switch>
        <Route path="/cc_login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/auth" component={LoginAuth} />
        <Route path="/review" component={Review} />
        <Route path="/view/:formId(\d+)/:documentId(\d+)" component={ViewQaApprovalCC} />
        <Route path="/hc_view/:ndDocumentId" component={HcDetails} />
        <Route path="/cc_home" component={Home} />
        <Route path="/reviews_home" component={ReviewHome} />
        <Route path="/not_found" component={NotFound} />
        <Route path="/PageFirm" component={Signers} />
        <Route path="/index" component={Index} />
        <Route path="/nd" component={NdRedirect} />
        <Route path="/request" component={QaRequest} />
        <Route path="/requestv1" component={QaRequestV1} />
        <Route path="/approval" component={QaApproval} />
        <Route path="/approvalv1" component={QaApprovalV1} />
        <Route path="/form" component={IndexForm} />
        <Route path="/no_verify" component={NoVerify} />
        <Route path="/now_verified" component={NowVerified} />
        <Route path="/administrador" component={Request} />
        <Route path="/auditory" component={Auditory} />
        <Redirect to="/not_found" />
      </Switch>
    </BrowserRouter>
  </div>
);

App.propTypes = {};

export default App;
