import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import View from "components/ReviewHome.view";

function ReviewHome() {

  const [isAuth, setIsAuth] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("id") != null) { //Preload data from localStorage
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("id", localStorage.getItem("id"));
      sessionStorage.setItem("role", localStorage.getItem("role"));
    }
    if (sessionStorage.getItem("id") != null && sessionStorage.getItem("role") == "PARTNER")
      setIsAuth(true);
    else window.location.replace("/cc_login");
  }, []);

  return <View />;
}

ReviewHome.propTypes = {};

export default ReviewHome;