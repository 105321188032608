import React from "react";
import PropTypes from "prop-types";
import Review from "components/partner/Review";


const ReviewDetailsView = ({ review }) =>
  <div>
    <Review review={review} isReadOnly={true} />
  </div>;

ReviewDetailsView.propTypes = {};

export default ReviewDetailsView;