import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import View from "components/cc/correction/Correction.view";

function Correction({onCompleted = () => null, onError = () => null, reqDocuments, documentId}) {
  const [error, setError] = useState(null);

  const [callSaveCorrection, reqSaveCorrection] = usePost("/documents/correction_admin", {
    onCompleted: () => {
      swal({
        title: "Revisión creado exitosamente",
        icon: "success",
        text: "La revisión se ha registrado exitosamente",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.location.href = "/cc_home";
        }
      });
    },
    onError: (data) => {
      console.log(data);
      if (data.status == 401) {
        setError("El documento no esta revisado, por favor reinicie la página");
      } else {
        setError("Error interno del servidor, intente mas tarde")
      }
    }
  });

  const reqDocument = useDetail(`{
    document {
      data
      createdAt
      updatedAt
      status
      request {
        ndDocumentId
      }
      file {
        url
      }
    }
  }`, documentId, {
    onError: (error) => {
      console.log(error);
      window.location.href = "/cc_home";
    }
  })

  const reqReviews = useQuery(`{
    reviews {
      createdAt
      updatedAt
      hasComments
      needsCommittee
      comments
      committees {
        id
      }
      dateReviewed
      dateApproved
    }
  }`, `document.id = ${documentId}`, { 
    onError: (error) => {
      console.log(error);
      window.location.href = "/not_found";
    },
    orderBy: "date_reviewed" 
  })

  if (reqDocument.loading || reqReviews.loading) return <Loading />;
  if (reqDocument.error || reqReviews.error) return "Error";

  const { reviews = [] } = reqReviews.data;
  const { document = {} } = reqDocument.data;

  if (document.status != "WITH_COMMENTS")
    window.location.href = "/cc_home";

  let lasReview = {};
  if (reviews.length > 0) lasReview = reviews[0];
  else window.location.href = "/cc_home";

  const onSubmit = (values) => {
    const comments = values["comments"]

    if (!comments) {
      setError("Debe llenar todos los campos");
      return;
    }

    callSaveCorrection({
      reviewId: lasReview.id,
      comments: comments,
    })

  }

  return <View review={lasReview} document={document} onSubmit={onSubmit} error={error} />;
}

Correction.propTypes = {
  onCompleted: PropTypes.func, 
  onError: PropTypes.func, 
  reqDocuments: PropTypes.object, 
  documentId: PropTypes.string,
  props: PropTypes.object,
  match: PropTypes.object
};

export default Correction;