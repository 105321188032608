import React from "react";
import { useHistory } from "react-router-dom";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/reviews/ReviewDetails.view";


function ReviewDetails({ match }) {

  const history = useHistory()
  const { reviewId = null } = match.params;
  if (!reviewId) history.goBack();

  const reqReview = useDetail(`{
    review {
      token
      deadline
      dateReviewed
      isDelayed
      hasComments
      comments
      needsCommittee
      document {
        data
        status
        request {
          ndDocumentId
        }
        file {
          url
        }
        request {
          id
        }
      }
    }
  }`, reviewId);

  if (reqReview.loading) return <Loading />;
  if (reqReview.error) window.location.replace("/logout");

  let review = reqReview.data?.review ? {...reqReview.data.review} : {};

  if (review?.hasComments != null && review?.hasComments != undefined)
    review.hasComments = review.hasComments ? "Yes" : "No";
  if (review?.needsCommittee != null && review?.needsCommittee != undefined)
    review.needsCommittee = review.needsCommittee ? "Yes" : "No";

  return <View review={review} />;
}

ReviewDetails.propTypes = {};

export default ReviewDetails;