import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import ReviewList from "components/reviews/ReviewList";
import ReviewDetails from "components/reviews/ReviewDetails";


const MainView = ({ user, filters, onChangeFilter }) => (

  <BrowserRouter basename="/reviews_home">

    <div class="card mb-3 mb-lg-5 p-3 h-100 w-100">
      <div class="card-header justify-content-between">
        <h3 class="card-header-title m-2">Revisiones</h3>
        <div className="d-flex align-items-center">

          <div class="d-flex mr-2 align-items-center">
            <span class="text-secondary mr-3 text-nowrap">
              Año revisión
            </span>
            <div class="input-group input-group-merge input-group-flush mr-3" style={{ width: "100px" }}>
              <select
                name="deadlineYear"
                value={filters["deadlineYear"]}
                class="form-control js-select2-custom js-datatable-filter custom-select"
                onChange={(event) => { onChangeFilter(event, "deadlineYear") }}
              >
                <option value="all" >Todos</option>
                {
                  Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - i).map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))
                }
              </select>
            </div>
            <span className="d-inline-block mr-5">
              <label class="toggle-switch d-flex align-items-center" for="reviewed">
                <input type="checkbox" class="toggle-switch-input" id="reviewed" name="reviewed"
                  onChange={(event) => { onChangeFilter(event, "reviewed") }} checked={filters["reviewed"]}
                />
                <span class="toggle-switch-label">
                  <span class="toggle-switch-indicator"></span>
                </span>
                <span class="toggle-switch-content mr-2">
                  <span class="text-secondary">Mostrar Revisadas</span>
                </span>

              </label>
            </span>
          </div>

          <span className="text-center">
            <b>{user?.firstName ?? ""} {user?.lastName ?? ""} <br /> ({user?.email ?? ""})</b>
          </span>
        </div>
      </div>

      <div class="card-body">
        <div class="card w-100 h-100">

          {/* Filters */}
          <></>

          {/* Review List */}
          <ReviewList filters={filters} />

        </div>
      </div>
    </div>

    <ModalRoute
      width="600"
      path="/:reviewId(\d+)/details"
      component={ReviewDetails}
    // reqDocuments={reqDocuments}
    />


  </BrowserRouter>
);

MainView.propTypes = {};

export default MainView;