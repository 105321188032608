import React from "react";
import { useGet } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/requests/HcDetails.view";

function HcDetails(props) {

  const { ndDocumentId = null } = props.match.params;
  if (!ndDocumentId) window.location.replace("/logout")

  const reqDataId = useGet("/requests/get_approval_document",
    { "nd_document_id": ndDocumentId });

  if (reqDataId.loading) return <Loading />;
  if (reqDataId.error) return window.location.replace("/not_found");

  const { request, document, approval } = reqDataId.data;
  if (!request || !document || !approval) window.location.replace("/not_found");

  return <View document={document} approval={approval} request={request} />;
}

HcDetails.propTypes = {};

export default HcDetails;