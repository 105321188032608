import React from "react";
import { PaginationFooter } from "seed/helpers";
import ReviewItem from "components/reviews/ReviewList.sub.ReviewItem.view";


const ReviewListView = ({
  reviews,
  totalPages,
  pageNum,
  isReviewedShown,
  onClickPage,
}) => (
  <>
    <div className="table-responsive">
      <table
        class="table table-borderless table-thead-bordered table-nowrap
        table-align-middle card-table"
      >

        <thead class="thead-light">
          <tr role="row">
            <th><span>Folio</span></th>
            <th><span>Documento</span></th>
            <th><span>Status</span></th>
            <th><span>
              {isReviewedShown ? "Detalles" : "Liga de aprobación"}
            </span></th>
          </tr>
        </thead>

        <tbody>
          {reviews.map((review, index) => (
            <ReviewItem review={review} key={index} />
          ))}
        </tbody>

      </table>
    </div>

    <PaginationFooter pageNum={pageNum} totalPages={totalPages} onClickPage={onClickPage} />
  </>
)

ReviewListView.propTypes = {};

export default ReviewListView;