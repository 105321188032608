import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import View from "components/cc/Main.view";

const defaultFilters = {
  search: "all",
  status: "all",
  delayed: false,
  ept: false,
  start_deadline: "all",
  end_deadline: "all",
  start_sync: "all",
  end_sync: "all",
  partner: "all",
  trash: false
};

function Main() {

  const id = sessionStorage.getItem("id");
  const pageSize = 25;
  const [pageNum, setPageNum] = useState(1);
  const [callLoading, setCallLoading] = useState(false);
  const [filters, setFilters] = useState(
    sessionStorage.getItem("def__filters") ?
      JSON.parse(sessionStorage.getItem("def__filters")) : defaultFilters);

  const [callUpdateDocuments, reqUpdateDocuments] = usePost("/documents/sync_documents", {
    onCompleted: (data) => {
      setCallLoading(false);
      swal({
        title: "Opiniones actualizadas",
        icon: "success",
        text: "Las opiniones han sido actualizadas correctamente.",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((response) => {
        window.location.reload();
      });

    },
    onError: (error) => {
      setCallLoading(false);
      console.log(error);
      swal({
        title: "No se puede procesar la petición",
        icon: "error",
        text: "Error del servidor, por favor intente más tarde.",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-error",
          },
        },
      }).then((response) => {
        window.location.reload();
      });
    }
  })

  const reqPartners = useQuery(`{
    users {
      firstName
      lastName
      email
    }
  }`, `status=PARTNER`, { orderBy: "first_name" });

  if (reqPartners.loading || callLoading) return <Loading />;
  if (reqPartners.error) return "Error";

  const { users = [] } = reqPartners.data;

  const onClickPage = (pageNum) => setPageNum(pageNum);

  const onChangeFilter = (event, type) => {
    let newFilters = Object.assign({}, filters);
    if (type == "delayed" || type == "ept") {
      newFilters[type] = !newFilters[type]
    } else {
      newFilters[type] = event.target.value;
    }
    sessionStorage.setItem("def__filters", JSON.stringify(newFilters));
    setFilters(newFilters);
  }

  const onClickUpdateDocuments = () => {

    swal({
      title: "Actualizar opiniones",
      icon: "warning",
      text: "Se actualizarán las opiniones registradas. ¿Desea continuar?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {

      if (response) {
        callUpdateDocuments({ emisorId: id });
        setCallLoading(true);
      }

    });

  }

  return (
    <View
      partners={users}
      filters={filters}
      pageNum={pageNum}
      setPageNum={setPageNum}
      pageSize={pageSize}
      onClickPage={onClickPage}
      onChangeFilter={onChangeFilter}
      onClickUpdateDocuments={onClickUpdateDocuments}
    />
  );
}

Main.propTypes = {
  trash: PropTypes.bool
};

export default Main;